<!--
 * @Description: 商品菜单
 * @Autor: zhangzhang
 * @Date: 2021-06-10 16:00:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-01-15 21:33:25
-->
<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar'
export default {
  components: {
    SideBar
  },

  data() {
    return {
      groups: [
        {
          label: '帮助中心',
          list: [
            {
              name: '帮助分类',
              path: '/mall/help/help-category'
            },
            {
              name: '帮助知识',
              path: '/mall/help/help-article'
            }
          ]
        }
      ]
    }
  }
}
</script>
